@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}


/* Remove input type numbers arrow" */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.toastui-calendar-see-more {
    transform: translate(-213px);
}
.toastui-calendar-see-more-container {
    display: none !important;
}
/*.toastui-calendar-popup-overlay {
    display: none !important;
}*/
.toastui-calendar-day-names.toastui-calendar-month {
    padding: 0 !important;
}
.toastui-calendar-template-monthDayName {
    text-align: center;
}
.toastui-calendar-grid-cell-header {
    position: relative;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}
.toastui-calendar-grid-cell-more-events {
    float: none;
    position: absolute;
    right: 0;
    left: 0;
    top: 156px;
    /*bottom: 7px;*/
    color: #82B3F4 !important;
    font-weight: 500 !important;
    outline: 0;
}
.toastui-calendar-weekday-event {
    height: 14px;
}
/*.toastui-calendar-daygrid-cell+.toastui-calendar-daygrid-cell {
    border-left: 0 !important;
}
.toastui-calendar-weekday-grid {
    border-top: 0 !important;
}*/

h1 {
    display: block;
    font-size: 2em;
    margin: 0.67em 0;
    font-weight: bold;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin: 0.83em 0;
    font-weight: bold;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin: 1em 0;
    font-weight: bold;
}

h4 {
    display: block;
    font-size: 1em;
    margin: 1.33em 0;
    font-weight: bold;
}

h5 {
    display: block;
    font-size: .83em;
    margin: 1.67em 0;
    font-weight: bold;
}

h6 {
    display: block;
    font-size: .67em;
    margin: 2.33em 0;
    font-weight: bold;
}

pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0;
}